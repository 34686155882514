import React, {useMemo} from 'react';
import {Stack, Heading} from '@shopify/polaris';
import TextBlockItem from './textBlockItem';
import {TEXT_STYLE_TYPES} from '../../../../../../constants/constants';
import './styles.css';

const TextSettingsBlock = (props) => {
  const {state, heading, blockType} = props;

  const renderBlockItems = useMemo(() => {
    return TEXT_STYLE_TYPES.map(({styleName, styleTitle}, index) => {
      return <TextBlockItem key={index} type={styleName} targetText={blockType} title={styleTitle} {...props}/>
    })
  }, [state, blockType]);

  return (
    <Stack vertical={true} spacing='tight'>
      {
        heading &&
        <Heading>{heading}</Heading>
      }
      {renderBlockItems}
      <hr className='Divider'/>
    </Stack>
  );
};

TextSettingsBlock.propTypes = {};

export default TextSettingsBlock;